package vegasful.admin

import com.steamstreet.graphkt.client.GraphQLClient
import com.steamstreet.graphkt.client.ktor.GraphQLKtorClient
import io.ktor.client.*
import io.ktor.client.call.*
import io.ktor.client.request.*
import kotlinx.browser.window
import kotlinx.serialization.Serializable
import kotlinx.serialization.json.Json
import vegasful.admin.account.CognitoClient
import vegasful.admin.api.client.*
import vegasful.admin.components.NotificationsConfig

@Serializable
data class Configuration(
    val authUrl: String,
    val clientId: String,
    val apiUrl: String
)

/**
 * The main application object.
 */
object Application {
    private val configParse = Json {
        ignoreUnknownKeys = true
    }

    lateinit var configuration: Configuration
    lateinit var cognito: CognitoClient
    private val origin = window.location.origin

    lateinit var api: GraphQLClient
    lateinit var navigation: HashNavigator
    lateinit var notifications: NotificationsConfig
    lateinit var domain: String

    suspend fun run() {
        configuration = configParse.decodeFromString(
            HttpClient().get("$origin/config.json").body()
        )
        cognito = CognitoClient(configuration.authUrl, configuration.clientId, origin)
        cognito.init()

        if (!cognito.isLoggedIn()) {
            cognito.login()
        } else {
            api = GraphQLKtorClient(configuration.apiUrl) {
                buildMap {
                    put("Authorization", cognito.rawIdToken!!)
                }
            }

            this.domain = api.admin {
                domain
            }.domain!!

            navigation = HashNavigator {
                renderPage(it)
            }
            navigation.run()
        }
    }

    suspend fun query(name: String? = null, block: _QueryQuery.() -> Unit): Query {
        return api.query(name, block)
    }

    suspend fun mutate(name: String? = null, block: _MutationQuery.() -> Unit): Mutation {
        return api.mutation(name, block)
    }
}

suspend fun GraphQLClient.admin(queryName: String? = null, query: _AdminMutationQuery.() -> Unit): AdminMutation {
    return mutation(queryName) {
        admin {
            query()
        }
    }.admin
}