package vegasful.admin

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.State
import androidx.compose.runtime.collectAsState
import com.steamstreet.graphkt.client.GraphQLClient
import com.steamstreet.graphkt.client.ktor.GraphQLKtorClient
import io.ktor.client.*
import io.ktor.client.call.*
import io.ktor.client.request.*
import kotlinx.browser.window
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.asStateFlow
import kotlinx.serialization.Serializable
import kotlinx.serialization.json.Json
import vegasful.admin.account.CognitoClient
import vegasful.admin.api.client.*
import vegasful.admin.components.NotificationsConfig
import kotlin.coroutines.CoroutineContext
import kotlin.coroutines.EmptyCoroutineContext

@Serializable
data class Configuration(
    val authUrl: String,
    val clientId: String,
    val apiUrl: String
)

/**
 * The main application object.
 */
object Application {
    private val configParse = Json {
        ignoreUnknownKeys = true
    }

    lateinit var configuration: Configuration
    lateinit var cognito: CognitoClient
    private val origin = window.location.origin

    lateinit var api: GraphQLClient
    lateinit var navigation: HashNavigator
    lateinit var notifications: NotificationsConfig
    lateinit var domain: String

    val content = ContentCache(this)

    suspend fun run() {
        configuration = configParse.decodeFromString(
            HttpClient().get("$origin/config.json").body()
        )
        cognito = CognitoClient(configuration.authUrl, configuration.clientId, origin)
        cognito.init()

        if (!cognito.isLoggedIn()) {
            cognito.login()
        } else {
            api = GraphQLKtorClient(configuration.apiUrl) {
                buildMap {
                    put("Authorization", cognito.rawIdToken!!)
                }
            }

            this.domain = api.admin {
                domain
            }.domain!!

            navigation = HashNavigator {
                renderPage(it)
            }
            navigation.run()
        }

        content.init()
    }

    suspend fun query(name: String? = null, block: _QueryQuery.() -> Unit): Query {
        return api.query(name, block)
    }

    suspend fun mutate(name: String? = null, block: _MutationQuery.() -> Unit): Mutation {
        return api.mutation(name, block)
    }
}

suspend fun GraphQLClient.admin(queryName: String? = null, query: _AdminMutationQuery.() -> Unit): AdminMutation {
    return mutation(queryName) {
        admin {
            query()
        }
    }.admin
}

/**
 * Stores global content for things like tags
 */
class ContentCache(val app: Application) {
    val tags = ViewModel {
        app.mutate {
            admin {
                tags {
                    all {
                        id
                        parent {
                            id
                        }
                        resolved {
                            id
                            name
                            path
                        }
                    }
                }
            }
        }.admin.tags.all
    }

    /**
     * Initialize and pre-loaded content.
     */
    fun init() {
    }
}

/**
 * Stores state as a flow that can be shared between components.
 */
class ViewModel<T>(private val initializer: suspend () -> T) {
    private val _mutableFlow = MutableStateFlow<T?>(null)
    private val value = _mutableFlow.asStateFlow()
    private var isInitialized = false

    @Composable
    fun state(context: CoroutineContext = EmptyCoroutineContext): State<T?> {
        LaunchedEffect(Unit) {
            if (!isInitialized) {
                isInitialized = true
                _mutableFlow.value = initializer()
            }
        }
        return value.collectAsState(context)
    }

    /**
     * Set the value of this model. Typically provided by the initializer,
     * but can be set manually.
     */
    fun setValue(newValue: T) {
        _mutableFlow.value = newValue
        isInitialized = true
    }

    /**
     * Refresh the value from the initializer.
     */
    @Suppress("unused")
    suspend fun refresh() {
        _mutableFlow.value = initializer()
    }

    /**
     * Clear the value. Initializer will be called when the state is next requested.
     */
    fun reset() {
        _mutableFlow.value = null
        isInitialized = false
    }
}