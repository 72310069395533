@file:Suppress("JSON_FORMAT_REDUNDANT_DEFAULT")

package vegasful.admin.api

import com.steamstreet.graphkt.ID
import kotlin.Boolean
import kotlin.Float
import kotlin.Int
import kotlin.String
import kotlin.Suppress
import kotlin.collections.List
import kotlinx.serialization.Serializable
import kotlinx.serialization.builtins.serializer
import kotlinx.serialization.json.Json

@Serializable
data class GlobalSearchInput(
  val text: String
)

@Serializable
data class ArticleSearchInput(
  val maxResults: Int? = null,
  val links: List<String>? = null
)

@Serializable
data class EventSearch(
  val start: String? = null,
  val end: String? = null,
  val pageSize: Int? = null,
  val pageToken: String? = null
)

@Serializable
data class EventAttributeInput(
  val key: String,
  val value: String
)

@Serializable
data class SystemEventInput(
  val attributes: List<EventAttributeInput>
)

@Serializable
data class SubscriptionInput(
  val userId: String? = null,
  val entityPath: String
)

@Serializable
data class ImageUpload(
  val data: String? = null,
  val type: String? = null,
  val uri: String? = null
)

/**
 *  The update information
 */
@Serializable
data class VenueUpdateInput(
  val name: String? = null,
  val description: String? = null,
  val address: AddressInput? = null,
  val location: GPSCoordinatesInput? = null,
  val links: List<SocialLinkInput>? = null,
  val ticketsUrl: String? = null,
  val parent: String? = null,
  val tags: List<String>? = null,
  val images: List<String>? = null,
  val aliases: List<String>? = null,
  val redirect: String? = null,
  val status: VenueStatus? = null,
  val rankingScore: Int? = null
)

@Serializable
data class SocialLinkInput(
  val type: String,
  val locator: String? = null
)

@Serializable
data class AddressInput(
  val street: String? = null,
  val city: String? = null,
  val state: String? = null,
  val zip: String? = null,
  val country: String? = null
)

@Serializable
data class GPSCoordinatesInput(
  val latitude: Float? = null,
  val longitude: Float? = null
)

/**
 *  The update information
 */
@Serializable
data class PerformerUpdateInput(
  val name: String? = null,
  val description: String? = null,
  val aliases: List<String>? = null,
  val tags: List<String>? = null,
  val images: List<String>? = null,
  val wikiIdentifier: String? = null,
  val rankingScore: Int? = null
)

@Serializable
data class MergeInput(
  val target: ID,
  val sources: List<ID>
)

@Serializable
data class EventUpdateInput(
  val parent: String? = null,
  val name: String? = null,
  val description: String? = null,
  val venue: String? = null,
  val date: String? = null,
  val time: String? = null,
  val duration: Int? = null,
  val endDate: String? = null,
  val endTime: String? = null,
  val ticketsUrl: String? = null,
  val sourceUrl: String? = null,
  val aliases: List<String>? = null,
  val images: List<String>? = null,
  val excludedImages: List<String>? = null,
  val tags: List<String>? = null,
  val excludedTags: List<String>? = null,
  val performers: List<String>? = null,
  val excluded: Boolean? = null,
  val rankingScore: Int? = null,
  val suggestedPerformers: List<String>? = null
)

@Serializable
data class ArticleInput(
  val title: String? = null,
  val summary: String? = null,
  val content: String? = null,
  val images: List<String>? = null,
  val links: List<String>? = null,
  val startTs: String? = null,
  val endTs: String? = null
)

@Serializable
data class TagUpdateInput(
  val name: String? = null,
  val parent: String? = null,
  val description: String? = null,
  val images: List<String>? = null,
  val shadow: Boolean? = null,
  val implied: List<String>? = null,
  val pageTitle: String? = null,
  val rankingScore: Int? = null,
  val aiPromptInput: String? = null
)

@Serializable
data class LoaderConfiguration(
  val name: String? = null,
  val module: ID? = null,
  val schedule: String? = null,
  val parameters: String? = null
)

@Serializable
data class LoaderExecutionInput(
  val data: String? = null
)

enum class VenueStatus {
  OPEN,

  CLOSED_TEMPORARILY,

  CLOSED_PERMANENTLY
}

typealias EventDate = String

typealias EventTime = String

typealias EventDateTime = String

typealias AWSDateTime = String

typealias EventDuration = String

internal val json: Json = Json {
  ignoreUnknownKeys = true
}

