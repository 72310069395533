package vegasful.admin.views

import androidx.compose.runtime.*
import io.ktor.http.*
import org.jetbrains.compose.web.attributes.InputType
import org.jetbrains.compose.web.attributes.disabled
import org.jetbrains.compose.web.dom.A
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Input
import org.jetbrains.compose.web.dom.Text
import vegasful.admin.Application
import vegasful.admin.api.TagUpdateInput
import vegasful.admin.api.client.*
import vegasful.admin.components.*

fun _AdminTagLayerQuery.tagLayerQuery() {
    name
    description
    pageTitle
    rankingScore
    aiPromptInput
    shadow
    this.images {
        imageThumbnailQuery()
    }
    impliedTags {
        id
        name
        description
    }
}

fun _TagQuery.parentQuery() {
    this.id
    this.name
    this.description
    this.path
    this.pageTitle
}

@Composable
fun tag(tagId: String?) {
    var tag by remember { mutableStateOf<AdminTag?>(null) }
    var localTag by remember { mutableStateOf<AdminTagLayer?>(null) }
    var parent by remember { mutableStateOf<Tag?>(null) }
    var children by remember { mutableStateOf<List<Tag>?>(null) }
    var update by remember { mutableStateOf(TagUpdateInput()) }
    var newId by remember { mutableStateOf(tagId) }
    var updatedParent by remember { mutableStateOf<Tag?>(null) }


    suspend fun updateTag(update: TagUpdateInput) {
        Application.mutate {
            admin {
                tags {
                    tag(tagId!!) {
                        local {
                            update(update) {
                                tagLayerQuery()
                            }
                        }
                    }
                }
            }
        }.admin.tags.tag.local.update.let {
            localTag = it
        }
    }

    suspend fun createTag(id: String, update: TagUpdateInput) {
        Application.mutate {
            admin {
                tags {
                    add(id, update) {
                        this.id
                    }
                }
            }
        }.admin.tags.add.id
    }

    /**
     * Save the general information
     */
    suspend fun save() {
        if (tagId == null && (newId?.length ?: 0) >= 3) {
            createTag(
                newId!!, update
            )
            Application.navigation.navigate {
                path("content", "tags", newId!!)
            }
        } else {
            try {
                updateTag(update)
                update = TagUpdateInput()
            } catch (t: Throwable) {
                Application.notifications.showError(t.message ?: "The save operation failed.")
            }
        }
    }

    LaunchedEffect(tagId) {
        localTag = null
        parent = null
        children = null
        if (tagId != null) {
            Application.mutate {
                admin {
                    tags {
                        tag(tagId) {
                            this.id
                            local {
                                tagLayerQuery()
                            }

                            this.parent {
                                parentQuery()
                            }

                            children {
                                this.id
                                this.name
                                this.path
                            }
                        }
                    }
                }
            }.admin.tags.tag.let {
                tag = it
                parent = it.parent
                children = it.children
                localTag = it.local
            }
        }
    }

    contentContainer {
        entityView {
            title = if (tagId == null) "New Tag" else localTag?.name
            breadcrumbs {
                crumb("Tags", "content/tags")
            }

            content {
                tabView {
                    tab("Details") {
                        box({

                        }) {
                            dialogField("ID") {
                                Input(InputType.Text) {
                                    if (tagId != null) {
                                        disabled()
                                    }
                                    value(newId.orEmpty())
                                    onInput {
                                        newId = it.value.ifBlank { null }
                                    }
                                }
                            }

                            dialogField("Parent") {
                                var parentEdit by remember { mutableStateOf(false) }
                                val aParent = updatedParent ?: parent
                                aParent?.let { p ->
                                    A(href = "#content/tags/${p.id}") {
                                        Text(p.name)
                                    }
                                }
                                if (parentEdit || aParent == null) {
                                    tagsSearch({ it.id != "root" }) {
                                        updatedParent = it
                                        update = update.copy(parent = it.id)
                                    }
                                } else {
                                    icon("edit") {
                                        action {
                                            parentEdit = true
                                        }
                                    }
                                }
                            }

                            dialogField("Name") {
                                simpleTextField(update.name ?: localTag?.name) {
                                    update = update.copy(name = it?.takeIf { it != localTag?.name })
                                }
                            }

                            dialogField("Description") {
                                richTextEditor(update.description ?: localTag?.description) {
                                    update = update.copy(description = it?.takeIf { it != localTag?.description })
                                }
                            }

                            dialogField("Page Title") {
                                simpleTextField(update.pageTitle ?: localTag?.pageTitle) {
                                    update = update.copy(pageTitle = it?.takeIf { it != localTag?.pageTitle })
                                }
                            }

                            dialogField("AI Hint") {
                                simpleTextField(update.aiPromptInput ?: localTag?.aiPromptInput) {
                                    update = update.copy(aiPromptInput = it?.takeIf { it != localTag?.aiPromptInput })
                                }
                            }

                            dialogField("Ranking Score") {
                                numberField(update.rankingScore ?: localTag?.rankingScore) {
                                    update = update.copy(rankingScore = it?.takeIf { it in 0..100 }?.toInt())
                                }
                            }

                            dialogField {
                                simpleCheckBox("Shadow", update.shadow ?: localTag?.shadow ?: false) {
                                    update = update.copy(shadow = it)
                                }
                            }

                            Div {
                                button("Save", true, true) {
                                    save()
                                }

                                button("Revert") {
                                    update = TagUpdateInput()
                                }
                            }
                        }

                        if (tagId != null) {
                            imagesBox(localTag?.images?.map { ImageBoxImage(it) }) { image, operation ->
                                when (operation) {
                                    ImageBoxOperation.ADD -> {
                                        updateTag(TagUpdateInput(
                                            images = (localTag?.images.orEmpty() + image).map { it.id }
                                        ))
                                    }

                                    ImageBoxOperation.REMOVE -> {
                                        updateTag(TagUpdateInput(
                                            images = localTag?.images.orEmpty().map { it.id } - image.id
                                        ))
                                    }

                                    ImageBoxOperation.EXCLUDE -> {}
                                    ImageBoxOperation.ENABLE -> {}
                                }
                            }

                            tagsBox(localTag?.impliedTags?.map {
                                TagBoxTag(it)
                            }.orEmpty(), "Implied Tags") { tag, operation ->
                                val tags = localTag?.impliedTags?.map { it.id }.orEmpty().toHashSet()
                                if (operation == TagOperation.REMOVE) {
                                    tags.remove(tag.id)
                                } else if (operation == TagOperation.ADD) {
                                    tags.add(tag.id)
                                }
                                updateTag(TagUpdateInput(implied = tags.toList()))
                            }

                            val hasChildren = !children.isNullOrEmpty()
                            box({
                                title = "Children"
                                paddedContent = !hasChildren
                            }) {
                                if (hasChildren) {
                                    table<Tag> {
                                        items(children.orEmpty())
                                        displayHeader = false
                                        column("Name") {
                                            content {
                                                A(href = "#content/tags/${it.id}") {
                                                    Text(it.name)
                                                }
                                            }
                                        }
                                    }
                                } else {
                                    boxMessage("This tag contains no other tags.")
                                }
                            }
                        }
                    }

                    if (tagId != null) {
                        tab("Events") {

                        }
                    }
                }

            }
        }
    }
}