package vegasful.admin.views

import androidx.compose.runtime.*
import kotlinx.datetime.Clock
import kotlinx.datetime.toInstant
import org.jetbrains.compose.web.attributes.ATarget
import org.jetbrains.compose.web.attributes.target
import org.jetbrains.compose.web.dom.A
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Text
import vegasful.admin.Application
import vegasful.admin.admin
import vegasful.admin.api.ArticleInput
import vegasful.admin.api.client.*
import vegasful.admin.components.*

/**
 * The query to get the entire article
 */
fun _AdminArticleQuery.fullArticleQuery() {
    id
    content
    summary
    created
    published
    title
    images {
        this.id
        uri
    }
    links {
        __typename
        id
        name
        path
    }

    startTs
    endTs
}

@Composable
fun article(id: String?) {
    var article by remember { mutableStateOf<AdminArticle?>(null) }
    var update by remember { mutableStateOf(ArticleInput()) }
    var images by remember { mutableStateOf<List<Image>>(emptyList()) }
    var tags by remember { mutableStateOf<List<Tag>?>(null) }
    var newId by remember { mutableStateOf(id) }

    LaunchedEffect(id ?: "new") {
        if (id != null) {
            val result = Application.api.admin {
                articles {
                    article(id) {
                        fullArticleQuery()
                    }
                }
            }

            tags = result.articles.article.links?.mapNotNull {
                it.path?.substringAfter("/tags/", "")?.ifBlank { null }
            }?.map { tagId ->
                Application.api.query {
                    tags {
                        this.tag(tagId) {
                            __typename
                            this.id
                            name
                            path
                        }
                    }
                }.tags.tag
            }

            article = result.articles.article
            images = result.articles.article.images.orEmpty()
        }
    }

    fun buildLinks(): List<Entity> {
        val nonTagLinks = article?.links?.filter { it.path?.removePrefix("/")?.startsWith("tag") != true }.orEmpty()
        return nonTagLinks + tags.orEmpty()
    }

    suspend fun save(input: ArticleInput) {
        if (id != null) {
            Application.api.admin {
                articles {
                    this.article(id) {
                        update(input.copy(
                            images = images.map { it.id },
                            links = buildLinks().map { it.path!! }
                        )) {
                            fullArticleQuery()
                        }
                    }
                }
            }.articles.article.update.let {
                article = it
            }
        } else {
            Application.api.admin {
                articles {
                    new(
                        newId?.ifBlank { null }, input.copy(
                        links = buildLinks().map { it.path!! }
                    )) {
                        fullArticleQuery()
                    }
                }
            }.articles.new.let {
                Application.navigation.navigate("content/articles/${it.id}")
            }
        }
    }

    suspend fun unpublish() {
        if (id != null) {
            try {
                article = Application.api.admin {
                    articles {
                        this.article(id) {
                            unpublish {
                                fullArticleQuery()
                            }
                        }
                    }
                }.articles.article.unpublish
            } catch (e: Throwable) {
                Application.notifications.showError("The article failed to unpublish: ${e.message}")
            }
        }
    }

    suspend fun publish() {
        if (id != null) {
            try {
                article = Application.api.admin {
                    articles {
                        this.article(id) {
                            publish(Clock.System.now().toString()) {
                                fullArticleQuery()
                            }
                        }
                    }
                }.articles.article.publish
            } catch (e: Throwable) {
                Application.notifications.showError("The article failed to publish: ${e.message}")
            }
        }
    }

    contentContainer {
        entityView {
            title = if (id != null) article?.title else "New Article"

            breadcrumbs {
                crumb("Articles", "content/articles")
            }

            content {
                box({
                    if (article?.published != null) {
                        action {
                            title = "Unpublish"
                            showProgressOnAction = true
                            action {
                                unpublish()
                            }
                        }
                    } else if (id != null) {
                        action {
                            title = "Publish"
                            primary = true
                            showProgressOnAction = true
                            action {
                                publish()
                            }
                        }
                    }
                }) {
                        dialogField("ID") {
                            if (id != null) {
                                A(href = "https://${Application.domain}/articles/${id}", { target(ATarget.Blank) }) {
                                    Text(id)
                                }
                            } else {
                                simpleTextField(newId) {
                                    newId = it
                                }
                            }
                        }

                    dialogField("Title") {
                        simpleTextField(update.title ?: article?.title) {
                            update = update.copy(title = it.takeIf { it != article?.title })
                        }
                    }

                    dialogField("Summary") {
                        simpleTextField(update.summary ?: article?.summary) {
                            update = update.copy(summary = it.takeIf { it != article?.summary })
                        }
                    }

                    dialogField("Article") {
                        richTextEditor(update.content ?: article?.content) {
                            update = update.copy(content = it.takeIf { it != article?.content })
                        }
                    }

                    imagesBox(images.map {
                        ImageBoxImage(it, removable = true, excludable = false, enableable = false)
                    }) { image, op ->
                        var newImages: List<Image> = images
                        if (op == ImageBoxOperation.ADD) {
                            newImages = images + image
                        } else if (op == ImageBoxOperation.REMOVE) {
                            newImages = images.filter { it.id != image.id }
                        }
                        images = newImages
                        update = update.copy(images = newImages.map { it.id })
                    }

                    tagsBox(tags.orEmpty().map { TagBoxTag(it) }) { tag, op ->
                        val newTags = tags.orEmpty().toMutableList()
                        when (op) {
                            TagOperation.ADD -> newTags += tag
                            TagOperation.REMOVE -> newTags -= tag
                            else -> {}
                        }
                        tags = newTags
                    }

                    box({
                        header({
                            title = "Events Filter"
                        }) {
                            Text("Events that will be displayed on the page with the article. (BETA)")
                        }
                    }) {
                        sideBySideFields {
                            dialogField("Start") {
                                instantField(update.startTs?.toInstant() ?: article?.startTs?.toInstant()) {
                                    update = update.copy(startTs = it?.toString())
                                }
                            }
                            dialogField("End") {
                                instantField(update.endTs?.toInstant() ?: article?.endTs?.toInstant()) {
                                    update = update.copy(endTs = it?.toString())
                                }
                            }

                            dialogField("Tags") {
                                tagsSearch({ true }) {

                                }
                            }
                            dialogField("Venues") {
                                venuesSearch({ true }) {

                                }
                            }
                        }
                    }

                    Div {
                        button {
                            title = "Save"
                            primary = true
                            showProgressOnAction = true
                            action {
                                save(update)
                                update = ArticleInput()
                            }
                        }

                        if (id != null) {
                            button("Revert") {
                                update = ArticleInput()
                            }
                        }
                    }
                }
            }
        }
    }
}