package vegasful.admin.components

import org.jetbrains.compose.web.css.*

fun StyleScope.borderBottom(
    width: CSSSizeValue<*>,
    style: LineStyle = LineStyle.Solid,
    color: CSSColorValue
) {
    property("border-color", color)
    property("border-style", style)
    borderWidth(0.px, 0.px, width, 0.px)
}